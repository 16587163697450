<template>
  <div class="container-fluid">
    <p>Progetto di ricerca dell'Università di Pisa</p>
    <p><strong>Responsabili del progetto</strong>: Prof.ssa Rita Biancheri, Prof.ssa Annalaura Carducci, Prof. Rudy Foddis</p>
    <p><strong>Gruppo di ricerca</strong>: Dott.ssa Elisa Caponi, Dott.ssa Silvia Cervia, Dott.ssa Giulia Ficini, Dott.ssa Fabiola Fontana, Dott.ssa Anna Taglioli</p>
    <p><strong>Realizzazione informatica</strong>: Andrea Cristaudo</p>
  </div>
</template>

<script>

export default {
};

</script>

<style lang="scss">
</style>
